// @flow
import React from 'react';
import { Ads } from '@finect/tabular-components/Ads';
import { Inner } from './partials';

type Props = {
  bottomMargin?: boolean,
  targeting?: Object,
  disableAutorefresh?: boolean,
  responsive?: boolean
};

export const TopAds = ({ bottomMargin = false, targeting = {}, disableAutorefresh = false, responsive }: Props) => (
  <Inner bottomMargin={bottomMargin}>
    <Ads
      id="Finect_Top1"
      targeting={targeting}
      autorefresh={disableAutorefresh ? undefined : true}
      responsive={responsive}
    />
  </Inner>
);

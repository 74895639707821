// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';

const followingStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.primary};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;

  span {
    padding-left: 11px;
    border-left: 1px solid rgb(255 255 255 / 0.25);
    margin-left: 12px;
    ${getFont(-2)}
    font-weight: 300;
    color: #fff;

    ${maxMediaQueries.phone`
      display: none;
    `};
  }
`;

const notFollowinfStyles = css`
  border: 1px solid
    ${({ theme, noBorder, primary }) => (noBorder ? 'transparent' : primary ? theme.colors.primary : theme.colors.uiM)};
  background-color: #fff;

  span {
    padding-left: 11px;
    border-left: 1px solid ${({ theme, primary }) => (primary ? theme.colors.terciaryL : theme.colors.uiM)};
    margin-left: 12px;
    ${getFont(-2)}
    color: ${({ theme }) => theme.colors.terciaryDD};

    ${maxMediaQueries.phone`
      display: none;
    `};
  }

  @media (hover: hover), screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    &:hover {
      ${({ theme }) => `border-color: ${theme.colors.primaryLL};`};
      background-color: ${({ theme }) => theme.colors.primaryLL};
      color: ${({ theme }) => theme.colors.primary};

      span {
        ${({ theme, primary }) => !primary && `border-color: ${theme.colors.primaryL};`};
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const defaultStyled = css`
  ${getFont(0)}
  line-height: 34px;
`;

const smallStyles = css`
  ${getFont(-2)}
  line-height: 25px;
`;

export const FollowButton = styled.button`
  padding: 0 15px;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-left: 9px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.bodyFontFamily};
  font-weight: 600;
  transition: all 0.15s ease;

  ${({ small }) => (small ? smallStyles : defaultStyled)};
  ${({ following }) => (following ? followingStyles : notFollowinfStyles)};
`;

type Props = {
  followers?: number,
  small?: boolean,
  noBorder?: boolean,
  onFollow?: Function,
  profileSubscriptions?: Array<string>,
  following?: boolean
};

export class Follow extends React.Component<Props> {
  render() {
    const { followers, following, onFollow, ...rest } = this.props;

    return (
      <FollowButton {...rest} following={following} onClick={() => onFollow()}>
        {following ? 'Siguiendo' : 'Seguir'}
        {followers && followers > 0 ? <span>{followers}</span> : null}
      </FollowButton>
    );
  }
}
